<template>
  <div v-if="winners.length > 0" class="row">
    <aside class="col-xs-12 col-lg-4">

      <!-- Filter: by name, station or show -->
      <div class="form-group / winners-app__filter winners-app__filter--station">
        <p><strong>Search by name, station or show</strong></p>
        <label for="search-name-station-show" class="required">Name/Station/Show</label>
        <input v-model="filter.keyword" type="text" id="search-name-station-show" placeholder="Name or station" name="search-name-station-show" maxlength="255" class="form-control">
      </div>

      <!-- Filter: by category -->
      <div class="form-group / winners-app__filter winners-app__filter--category">
        <p><strong>Fine tune your search</strong></p>
        <label for="search-category">Award Categories</label>
        <input v-model="filter.category" type="text" id="search-category" placeholder="Category name" name="search-category" maxlength="255" class="form-control">
      </div>

      <!-- Filter: by year -->
      <div class="form-group / winners-app__filter">
        <label for="search-year">Year</label>
        <select v-model="filter.year" class="custom-select" id="search-year" name="search-year">
          <option disabled value="">Please select one</option>
          <option value="all">All years</option>
          <option v-for="year in years" :value="year">
            {{ year }}
          </option>

        </select>
      </div>

      <!-- Filter: by type -->
      <div class="form-group / winners-app__filter">
        <label for="search-type">Place</label>
        <div class="custom-control custom-checkbox">
          <input v-model="filter.showWinners" type="checkbox" class="custom-control-input" id="search-type-winner">
          <label class="custom-control-label" for="search-type-winner">Winner</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input v-model="filter.showFinalists" type="checkbox" class="custom-control-input" id="search-type-finalist">
          <label class="custom-control-label" for="search-type-finalist">Finalist</label>
        </div>
      </div>

    </aside>
    <section class="col-xs-12 col-lg-8">

      <h2 class="winners-app__results">Showing {{ filteredWinners.length }} results</h2>

      <!-- Results -->
      <div v-if="filteredWinners.length > 0">
        <div v-for="item in filteredWinners" class="winners-app__item" :class="{'winners-app__item--winner': item.place == 'Winner'}">
          <div class="winners-app__item-header">
            <p>{{ item.award }}</p>
            <p>{{ item.year }}</p>
          </div>
          <div class="winners-app__item-body / mb-lc-0">
            <p class="winners-app__item-type">{{ item.place }}</p>
            <p>{{ item.recipient }}</p>
            <p v-if="item.bio">{{ item.bio }}</p>
          </div>
        </div>
      </div>

      <!-- No Results -->
      <div v-if="filteredWinners.length === 0">
        <p>There are no results matching your criteria.</p>
      </div>

    </section>
  </div>
</template>

<script>

import { toRaw } from "vue";

export default {
  name: "winners",
  props: {
    winnersData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      heading: '',
      winners: [],
      years: null,
      sort: 'chronological',
      filterKeywordSynonyms: [],
      filter:{
        keyword: '',
        category: '',
        year: '',
        showWinners: false,
        showFinalists: false,
      }
    }
  },
  mounted() {

    // Assign props to vue component data
    this.winners = this.winnersData;

    // Extract filters from dataset
    this.extractFilters();

    // Add Filter Keyword Synonyms
    this.addKeywordSynonyms();

  },
  computed: {
    filteredWinners() {

      let tempBlocks = this.winners;

      // Process keyword input
      if (this.filter.keyword && this.filter.keyword != '' && this.filter.keyword.length > 1) {
        let synonymsArr = this.checkKeywordSynonyms();
        tempBlocks = tempBlocks.filter((item) => {

          let itemPass = false;
          let itemKeywordArr = item.recipient.split(',').flat();

          // Loop through item keywords
          Array.from(itemKeywordArr).forEach((itemKeyword) => {

            // If synonyms exist for the entered keyword...
            // Check synonym against the item keyword
            if(synonymsArr){
              Array.from(synonymsArr).forEach((synonym) => {
                if (itemKeyword.toLowerCase().trim() === synonym.toLowerCase().trim()) {
                  itemPass = true;
                }
              });
            }

            // Check entered keyword against the item keyword
            if(!synonymsArr){
              if (itemKeyword.toLowerCase().trim().includes(this.filter.keyword.toLowerCase().trim())) {
                itemPass = true;
              }
            }

          });

          return itemPass;
        });
      };

      // Process category input
      if (this.filter.category && this.filter.category != '' && this.filter.category.length > 2) {
        tempBlocks = tempBlocks.filter((item) => {
          let keywordString = `${item.award}`
          return keywordString.toLowerCase().includes(this.filter.category.toLowerCase());
        });
      };

      // Filter out by year
      if (this.filter.year && this.filter.year != '' && this.filter.year != 'all'){
        tempBlocks = tempBlocks.filter((item) => {
          return (item.year == this.filter.year);
        });
      }

      // Show Winners/Finalists
      if (this.filter.showWinners || this.filter.showFinalists){
        tempBlocks = tempBlocks.filter((item) => {

          // Show winners and finalists...
          if(this.filter.showWinners && this.filter.showFinalists){
            return (item.place.includes('Winner') || item.place.includes('Finalist'));
          }

          // Show winners only
          if(this.filter.showWinners && !this.filter.showFinalists){
            return (item.place.includes('Winner'));
          }

          // Show finalists only
          if(this.filter.showFinalists && !this.filter.showWinners){
            return (item.place.includes('Finalist'));
          }

        });
      }

      // Sort chronological order
      if (this.sort == 'chronological') {
        tempBlocks = tempBlocks.sort((a, b) => {
          let fa = a.year;
          let fb = b.year;
          if (fa < fb) {
            return 1;
          };
          if (fa > fb) {
            return -1;
          };
          return 0;
        });
      }

      return tempBlocks;

    }
  },
  methods: {
    addKeywordSynonyms(){
      let synonyms = [
          ['RNZ', 'Radio NZ', 'Radio New Zealand', 'National Radio', 'Concert FM'],
          ['ZB', 'ZB Network', 'Newstalk ZB', '1ZB'],
          ['ZM', 'ZM Network', '91ZM']
      ];
      Array.from(synonyms).forEach((list) => {
        this.filterKeywordSynonyms.push(list);
      });

    },
    checkKeywordSynonyms(){
      let synonymsToAdd = '';

      Array.from(this.filterKeywordSynonyms).forEach((synonymSet) => {
        Array.from(synonymSet).forEach((synonym) => {
            if (this.filter.keyword.toLowerCase() === synonym.toLowerCase()) {
              // console.log('found in list');
              // console.log(synonymSet);
              // console.log(synonym);
              synonymsToAdd = synonymSet;
            }
        });
      });

      return synonymsToAdd;
    },
    extractFilters(){

      let blocksData = toRaw(this.winners);
      let tmpYears = [];

      // Years
      Object.entries(blocksData).map(item => {
        if(item[1].year){
          if (!tmpYears.includes(item[1].year)) {
            tmpYears.push(item[1].year);
          }
        }
      });

      this.years = tmpYears.reverse();

    }
  }
}
</script>