import { createApp } from 'vue';

// Entry points into the Vue application
import Winners from './vue/Winners.vue';

// Winners
if(document.querySelector('#winners-app')){
	// const winners = createApp(Winners);
	// winners.mount('#winners-app');

	let winnersAppEl = document.querySelector('#winners-app');
	// Array.from(videoPlayers).forEach((videoPlayerEl) => {
		let data = JSON.parse(winnersAppEl.dataset.winners);
		createApp(Winners, {winnersData: data}).mount(winnersAppEl);
	// });
// console.log(data);

}